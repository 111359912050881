import React, { useState } from "react";
import Header from "../directives/Header";
import Footer from "../directives/Footer";
import "../componentCss/home.css";
import {
    Container,
    Row,
    Col,
    Button,
    Card,
    Modal
} from "react-bootstrap";
import { Zoom, Fade, Roll, Slide } from 'react-reveal';



const Home = ({ text }) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [isCollapsed, setIsCollapsed] = useState(true);

    const handleToggleCollapse = () => {
        setIsCollapsed(!isCollapsed);
    };
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };


    return (
        <>
            <Header />
            <section className="banner py-lg-5 pt-4 position-relative">
                {/* <div className="banner-bg">
                    <img src="assets/images/globe.png"/>
                </div> */}
                <Container className="pt-5">
                    <Row className="justify-content-center">
                        <Col lg={12} md={12} className="bannerForm">
                            <div className="banner-content">
                                <div className="d-md-flex text-sm-center align-items-center mb-2">

                                    <div className="banner-logo me-4 mb-sm-3">
                                        <Zoom>
                                            <img src="assets/images/logo_animated.gif" width={`150px`} />
                                        </Zoom>
                                    </div>
                                    <div>
                                        <Fade left>
                                            <h3
                                                className="mb-0 fw-normal" style={{ color: "#FFFDF6" }}>
                                                THE NEXT GENERATION SOCIAL ENTREPRENEURS AFFILIATE SYSTEM
                                            </h3>
                                        </Fade>
                                    </div>
                                </div>
                                {/* <Row className="align-items-center">
                                    <Col lg={2} className="text-center">
                                        <Zoom>
                                            <div className="banner-logo ">
                                                <img src="assets/images/logo_animated.gif" width={`150px`} />
                                            </div>
                                        </Zoom>
                                    </Col>
                                    <Col lg={10}>
                                        <Fade left>

                                            <h3
                                                className="mb-0 fw-normal" style={{ color: "#FFFDF6" }}>
                                                THE NEXT GENERATION SOCIAL ENTREPRENEURS AFFILIATE SYSTEM
                                            </h3>

                                        </Fade>
                                    </Col>
                                </Row> */}

                                <Fade right>
                                    <div className="text-center">
                                        <p className="mt-1 mb-5 fs-5 px-2 fw-normal" style={{ color: "#EAEAEA" }}>Hi5 Space is an innovative and interactive community platform that brings together committed social
                                            <br />entrepreneurs from around the globe <span className="">with the mission to ignite a worldwide humanitarian movement  and establish a lasting legacy that resonates for generations to come.</span></p>
                                    </div>
                                </Fade>
                                <div className="it_head">
                                    <div className="text-center ">
                                        <Fade right>

                                            <h3 className="mb-3 fw-bold">Hi5 SPACE GIVES A SENSE OF LIFE PURPOSE</h3>

                                        </Fade>

                                        <Zoom>
                                            <h1 className="fw-bold m-head mb-4">IT STARTS FROM YOU</h1>
                                        </Zoom>
                                    </div>

                                </div>
                                <div className="text-center">
                                    <Fade bottom>
                                        <Button variant="primary" className="my-3 px-4" onClick={handleShow}>Buy USDT & MATIC/POL</Button>
                                    </Fade>
                                </div>
                                {/* -------Buy Matic Start-------- */}
                                <Modal show={show} onHide={handleClose} centered>
                                    <Modal.Header className="border-0" closeButton>
                                        <Modal.Title></Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="py-5">
                                        <div className="text-center" style={{ marginTop: "-50px" }}>
                                            <div className="modal-p-logo">
                                                <ul>
                                                    <li><a href="https://accounts.binance.com/register?ref=35458296" target="_blank" className="text-decoration-none">
                                                        <img src="assets/images/icon/Binance_Logo.png" width={`70px`} style={{ filter: "drop-shadow(0px 0px 0px #4444dd)" }} />
                                                        <h5 className="text-white mt-2 mb-0 fw-lighter">Binance</h5>
                                                    </a></li>
                                                    <li><a href="https://www.htx.com/invite/en-us/1f?invite_code=xyv82223" target="_blank" className="text-decoration-none">
                                                        <img src="assets/images/icon/HTX_logo.png" width={`70px`} style={{ filter: "drop-shadow(0px 0px 0px #4444dd)" }} />
                                                        <h5 className="text-white mt-2 mb-0 fw-lighter">HTX</h5>
                                                    </a></li>
                                                    <li><a href="https://app.uniswap.org/" target="_blank" className="text-decoration-none">
                                                        <img src="assets/images/icon/uniswap_logo.png" width={`70px`} style={{ filter: "drop-shadow(0px 0px 0px #4444dd)" }} />
                                                        <h5 className="text-white mt-2 mb-0 fw-lighter">Uniswap</h5>
                                                    </a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Modal.Body>

                                </Modal>
                                {/* -------Buy Matic End-------- */}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="empowering py-lg-5 pt-3 position-relative">
                <div className="wave_line">
                    <img src="assets/images/waveline.png" />
                </div>
                <Container className="">
                    <Row className="justify-content-center">
                        <Col xl={8} lg={8} md={8} className="text-center">
                            <Fade top>
                                <div className="heading">
                                    <h2 className="fw-bold lh-base mb-0">YOU CAN MAKE <span className="text-primary">A DIFFERENCE</span> <br /> IN SOMEONE'S LIFE WHILE YOU'RE STILL ALIVE</h2>
                                </div>
                            </Fade>
                        </Col>
                        <Col lg={12} className="">
                            <Fade right>
                                <div className="mt-3">
                                    <p className="mb-2" style={{ color: "#FFFDF6" }}>The world's first next-generation social entrepreneurship accelerator program brings together visionary individuals and community members who are committed to driving positive change.</p>
                                    <p style={{ color: "#FFFDF6" }}> Its aim is to create sustainable social impact through an independent social-economic empowering ecosystem based on unity in diversity and equality.</p>
                                </div>
                            </Fade>
                            <Zoom>
                                <div className="feature-list text-center pt-3">
                                    <Container>
                                        <Row>
                                            <Col xl={2} lg={4} md={4} sm={6} xs={6} className="mb-3">

                                                <div className="box">
                                                    <img src="assets/images/icon/forex.svg" />
                                                    <div className="small">NO FOREX TRADING</div>
                                                </div>


                                            </Col>
                                            <Col xl={2} lg={4} md={4} sm={6} xs={6} className="mb-3">

                                                <div className="box">
                                                    <img src="assets/images/icon/business-modal.svg" />
                                                    <div className="small">NO BUSINESS MODEL</div>
                                                </div>


                                            </Col>
                                            <Col xl={2} lg={4} md={4} sm={6} xs={6} className="mb-3">

                                                <div className="box">
                                                    <img src="assets/images/icon/sell-and-buy.svg" />
                                                    <div className="small">NO BUYING<br /> NO SELLING</div>
                                                </div>


                                            </Col>
                                            <Col xl={2} lg={4} md={4} sm={6} xs={6} className="mb-3">

                                                <div className="box">
                                                    <img src="assets/images/icon/return-on-investment.svg" />
                                                    <div className="small">NO INVESTMENT ACTIVITY</div>
                                                </div>


                                            </Col>
                                            <Col xl={2} lg={4} md={4} sm={6} xs={6} className="mb-3">

                                                <div className="box">
                                                    <img src="assets/images/icon/subscription.svg" />
                                                    <div className="small">NO MONTHLY SUBSCRIPTION</div>
                                                </div>


                                            </Col>
                                            <Col xl={2} lg={4} md={4} sm={6} xs={6} className="mb-3">

                                                <div className="box">
                                                    <img src="assets/images/icon/organization.svg" />
                                                    <div className="small">NO MIDDLEMAN<br />NO COMPANY</div>
                                                </div>


                                            </Col>
                                        </Row>
                                    </Container>

                                </div>
                            </Zoom>

                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col lg={12}>
                            <Zoom>
                                <div>

                                    {!isCollapsed && <div>
                                        <p>The next generation of social entrepreneurs leverages blockchain
                                            technology, embraces sustainability, and utilizes the innovative
                                            Hi5 Space platform to address social issues such as poverty, education,
                                            healthcare, and environmental sustainability. They bring creative
                                            approaches to tackle these challenges, driven by a strong sense of
                                            purpose and a desire to make a meaningful impact on the global
                                            community.</p>
                                        <p>The Hi5 Space program features a fully integrated and automated
                                            Space Loop set of protocols driven by an intelligent algorithm system.
                                            Additionally, it utilizes auto powerline placement using the global
                                            community, according to the Polygon transaction timestamp.</p>
                                        <p>Our platform is built on smart contract blockchain technology and uses
                                            the Polygon USDT cryptocurrency. It is 100% functional for everyone
                                            through peer-to-peer wallet interactions. This unique automated
                                            crowdfunding affiliate system is powered by Hi5 Space Loop super
                                            technology.</p>
                                    </div>}
                                    <div className="text-center">
                                        <Button variant="primary" className="my-3 px-4" onClick={handleToggleCollapse}>
                                            {isCollapsed ? 'Show More' : 'Show Less'}
                                        </Button>
                                    </div>
                                </div>
                                {/* <div >
                                    {showMore ? (
                                        <div className="text-left my-3">
                                            <p>The next generation of social entrepreneurs leverages blockchain
                                                technology, embraces sustainability, and utilizes the innovative
                                                Hi5 Space platform to address social issues such as poverty, education,
                                                healthcare, and environmental sustainability. They bring creative
                                                approaches to tackle these challenges, driven by a strong sense of
                                                purpose and a desire to make a meaningful impact on the global
                                                community.</p>
                                            <p>The Hi5 Space program features a fully integrated and automated
                                                Space Loop set of protocols driven by an intelligent algorithm system.
                                                Additionally, it utilizes auto powerline placement using the global
                                                community, according to the Polygon transaction timestamp.</p>
                                            <p>Our platform is built on smart contract blockchain technology and uses
                                                the Polygon Matic cryptocurrency. It is 100% functional for everyone
                                                through peer-to-peer wallet interactions. This unique automated
                                                crowdfunding affiliate system is powered by Hi5 Space Loop super
                                                technology.</p>

                                        </div>
                                    ) : (
                                        <div className="text-center">
                                            <Button variant="primary" className="my-3 px-4" onClick={handleViewMoreClick}>Show More</Button>
                                        </div>
                                    )}
                                </div> */}

                            </Zoom>
                        </Col>
                    </Row>
                </Container>
                <div className="py-lg-5 pb-lg-0 pb-0 pt-3 position-relative">

                    <div className="shape-bg">
                        <img src="assets/images/left-ellipse.svg" className="left-shape shape" />
                        <div className="left-shape">
                            <img src="assets/images/right-ellipse.svg" className="right-ellipse-shape shape" width={`220px`} />
                            <img src="assets/images/line-circle.png" className="line-circle-shape shape" />
                        </div>
                    </div>

                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={10} md={10}>
                                <Fade top>
                                    <div className="heading text-center">
                                        <h2 className="fw-bold lh-base mb-0"><span className="text-primary">Hi5 SPACE</span> EMPOWERING EVERYONE WITH A NEW LIFE</h2>

                                        <div className="my-3">
                                            <p className=" fs-4 fw-normal mb-0">Creating The Space of Life For You, Your Family and Your Community</p>
                                            <p className="fs-4 fw-normal">One World - <span className="text-primary">One Community</span> - One Race - <span className="text-primary">One Religion</span> - One Movement</p>
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col lg={12} className=" my-3">
                                <Container>
                                    <Row className="align-items-center">
                                        <Col lg={6} className="mb-5">
                                            <Fade left>
                                                <div>
                                                    <div>
                                                        <h5 className="fw-medium">The world often overlooks the billions of people living in poverty. </h5>
                                                        <p>Nearly half of the world's population lives in extreme poverty, surviving on less than USD 2.50 a day. According to UNICEF, one billion children worldwide live in poverty, and more than 20,000 of them die every day. What is your social responsibility?</p>
                                                    </div>
                                                    {/* <Button className="bg-transparent text-primary border-0 mt-2 p-0" >Read More..</Button> */}


                                                </div>
                                            </Fade>
                                        </Col>

                                        <Col lg={6} className="mb-5">
                                            <Zoom>
                                                <div>
                                                    <img src="assets/images/people-living.png" />
                                                </div>
                                            </Zoom>
                                        </Col>
                                        <Col lg={6} className="mb-5">
                                            <Zoom>
                                                <div>
                                                    <img src="assets/images/world-money.png" />
                                                </div>
                                            </Zoom>
                                        </Col>
                                        <Col lg={6} className="mb-5">
                                            <Fade right>
                                                <div>
                                                    <div>
                                                        <h5 className="fw-medium">The world has lots of money </h5>
                                                        <p className="mb-1">But the real issue is that we don't have a proper wealth distribution system.
                                                            This is why the rich keep getting richer while the poor become poorer. Sadly,
                                                            many of us live in our own bubbles, ignoring the needs of billions of people
                                                            worldwide.</p>
                                                        <p>What is your social responsibility?</p>
                                                    </div>
                                                    {/* <Button className="bg-transparent text-primary border-0 mt-2 p-0">Read More..</Button> */}
                                                </div>
                                            </Fade>
                                        </Col>

                                        <Col lg={6} className="mb-5">
                                            <Fade left>
                                                <div>
                                                    <div>
                                                        <h5 className="fw-medium">Life's more persistent and urgent question is.</h5>
                                                        <p>What are you doing for others?<br /> Let us unite with teams of
                                                            people who are motivated by love, humanity, and generosity to
                                                            strengthen our social responsibility by sharing our resources
                                                            and uplifting our communities. Our main focus is to enrich
                                                            each other's lives towards creating a better world for everyone.</p>
                                                    </div>
                                                    {/* <Button className="bg-transparent text-primary border-0 mt-2 p-0">Read More..</Button> */}
                                                </div>
                                            </Fade>
                                        </Col>
                                        <Col lg={6} className="mb-5">
                                            <Zoom>
                                                <div>
                                                    <img src="assets/images/member.png" />
                                                </div>
                                            </Zoom>
                                        </Col>

                                    </Row>
                                </Container>

                            </Col>
                        </Row>
                    </Container>
                </div>

            </section>
            {/* <section className="py-lg-5 pb-0 pt-3 position-relative">

                <Container>
                    <Row className="justify-content-center">
                        <Col lg={10} md={10}>

                            <div className="heading text-center">
                                <h2 className="fw-bold lh-base mb-0"><span className="text-primary">Hi5 SPACE</span> EMPOWERING EVERYONE WITH A NEW LIFE</h2>
                                <p className="fw-normal mb-1">Creating The space of life for you, your family and your community</p>
                                <p className="small">One World - <span className="text-primary">One Community</span> - One Race - <span className="text-primary">One Religion</span> - One Movement</p>
                            </div>
                        </Col>
                        <Col lg={12} className=" my-3">
                            <Container>
                                <Row className="align-items-center">
                                    <Col lg={6} className="mb-5">
                                        <div>
                                            <div>
                                                <h5 className="fw-medium">The world often overlooks the billions of people living in poverty. </h5>
                                                <p>Nearly half of the world's population lives in extreme poverty, surviving on less than USD 2.50 a day. According to UNICEF, one billion children worldwide live in poverty, and more than 20,000 of them die every day. What is your social responsibility?</p>
                                            </div>
                                            <Button className="bg-transparent text-primary border-0 mt-2 p-0">Read More..</Button>
                                        </div>
                                    </Col>

                                    <Col lg={6} className="mb-5">
                                        <div>
                                            <img src="assets/images/people-living.png" />
                                        </div>
                                    </Col>
                                    <Col lg={6} className="mb-5">
                                        <div>
                                            <img src="assets/images/world-money.png" />
                                        </div>
                                    </Col>
                                    <Col lg={6} className="mb-5">
                                        <div>
                                            <div>
                                                <h5 className="fw-medium">The world often overlooks the billions of people living in poverty. </h5>
                                                <p>Nearly half of the world's population lives in extreme poverty, surviving on less than USD 2.50 a day. According to UNICEF, one billion children worldwide live in poverty, and more than 20,000 of them die every day. What is your social responsibility?</p>
                                            </div>
                                            <Button className="bg-transparent text-primary border-0 mt-2 p-0">Read More..</Button>
                                        </div>
                                    </Col>

                                    <Col lg={6} className="mb-5">
                                        <div>
                                            <div>
                                                <h5 className="fw-medium">The world often overlooks the billions of people living in poverty. </h5>
                                                <p>Nearly half of the world's population lives in extreme poverty, surviving on less than USD 2.50 a day. According to UNICEF, one billion children worldwide live in poverty, and more than 20,000 of them die every day. What is your social responsibility?</p>
                                            </div>
                                            <Button className="bg-transparent text-primary border-0 mt-2 p-0">Read More..</Button>
                                        </div>
                                    </Col>
                                    <Col lg={6} className="mb-5">
                                        <div>
                                            <img src="assets/images/member.png" />
                                        </div>
                                    </Col>

                                </Row>
                            </Container>

                        </Col>
                    </Row>
                </Container>
            </section> */}
            <div className="bg-galaxy">
                <section className="py-lg-5 pt-3 position-relative">

                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={12} md={12}>
                                <Fade top>
                                    <div className="heading text-center mb-3">
                                        <h2 className="fw-bold lh-base mb-0 text-uppercase">Why Choose <span className="text-primary">Hi5 Space?</span></h2>
                                    </div>
                                    <div className="">
                                        <p>Our mission is to ignite a worldwide humanitarian movement dedicated to increasing awareness and garnering support for
                                            individuals and community members across the world who are facing significant challenges in their lives.
                                        </p>
                                        <p>We promote a global social entrepreneurship movement where 100% of the Hi5 Space donation crowdfunding is distributed
                                            among the community</p>
                                    </div>
                                </Fade>
                            </Col>
                            <Col lg={12} className="my-3 howitwork">
                                <Zoom>
                                    <Card className="bg-light-grey p-5 rounded-5">
                                        <Container className="mb-3">
                                            <Row className="">
                                                <Col xl={3} lg={6} md={6} className="mb-5 text-center">
                                                    <div>
                                                        <img src="assets/images/whychoose/freeregistrations.png" />
                                                        <h6 className="mt-3">FREE REGISTRATION</h6>
                                                        <p>Join as inactive donor & receive invite donations
                                                            from your direct active donors</p>
                                                    </div>
                                                </Col>
                                                <Col xl={3} lg={6} md={6} className="mb-5 text-center">
                                                    <div>
                                                        <img src="assets/images/whychoose/autorebirth.png" />
                                                        <h6 className="mt-3">AUTO REBIRTH POSITIONS</h6>
                                                        <p>Auto dynamic movement with unlimited space positions using the same wallet address</p>
                                                    </div>
                                                </Col>
                                                <Col xl={3} lg={6} md={6} className="mb-5 text-center">
                                                    <div>
                                                        <img src="assets/images/whychoose/easyactivation.png" />
                                                        <h6 className="mt-3">EASY ACTIVATION</h6>
                                                        <p>Start to become an active donor with a one-time out of pocket USD 50 Donation</p>
                                                    </div>
                                                </Col>
                                                <Col xl={3} lg={6} md={6} className="mb-5 text-center">
                                                    <div>
                                                        <img src="assets/images/whychoose/safetyandsecurity.png" />
                                                        <h6 className="mt-3">SAFETY AND SECURITY</h6>
                                                        <p>Receive unlimited space donations
                                                            directly info your personal private wallet</p>
                                                    </div>
                                                </Col>

                                            </Row>
                                        </Container>
                                        <Container>
                                            <Row className="">
                                                <Col lg={12} className="text-center mb-5">
                                                    <h3>Easy <span className="text-primary">Wallet Connection</span></h3>
                                                    <h5>We are Supported By More than 100 Wallets </h5>
                                                </Col>
                                                <Col lg={12} className="text-center">
                                                    <div className="currency-list">
                                                        <ul className="list-group d-inline-block mx-auto">
                                                            <li className="list-group-item border-0 bg-transparent d-inline-block">
                                                                <img src="assets/images/icon/currency/metamask.png" />
                                                            </li>
                                                            <li className="list-group-item border-0 bg-transparent d-inline-block">
                                                                <img src="assets/images/icon/currency/blockchain.png" />
                                                            </li>
                                                            <li className="list-group-item border-0 bg-transparent d-inline-block">
                                                                <img src="assets/images/icon/currency/coinbasewallet.png" />
                                                            </li>
                                                            <li className="list-group-item border-0 bg-transparent d-inline-block">
                                                                <img src="assets/images/icon/currency/bitcoin.png" />
                                                            </li>
                                                            <li className="list-group-item border-0 bg-transparent d-inline-block">
                                                                <img src="assets/images/icon/currency/ethereum.png" />
                                                            </li>
                                                            <li className="list-group-item border-0 bg-transparent d-inline-block">
                                                                <img src="assets/images/icon/currency/solana.png" />
                                                            </li>
                                                            <li className="list-group-item border-0 bg-transparent d-inline-block">
                                                                <img src="assets/images/icon/currency/trustwallet.png" />
                                                            </li>
                                                            <li className="list-group-item border-0 bg-transparent d-inline-block">
                                                                <img src="assets/images/icon/currency/binance.png" />
                                                            </li>
                                                            <li className="list-group-item border-0 bg-transparent d-inline-block">
                                                                <img src="assets/images/icon/currency/exoduswallet.png" />
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </Col>


                                            </Row>
                                        </Container>

                                    </Card>
                                </Zoom>
                            </Col>
                        </Row>
                    </Container>
                </section>
                <section className="py-lg-5 pt-3 position-relative">
                    <Container>
                        <Row className="align-items-center">
                            <Col lg={6} md={6} className="mb-5">
                                <Fade left>
                                    <div className="heading">
                                        <h2 className="fw-bold lh-base mb-2 text-uppercase "><span className="text-primary">Hi5 Space-</span> Your passport to change the World</h2>
                                        <h6 className="mb-3">ONE WORLD ONE TEAM ONE GLOBAL POWERLINE MOVEMENT</h6>
                                        <h6 className="mb-2">WITH RECURRENT AUTO DYNAMIC REBIRTH SPACE MATRIX
                                            POSITIONS
                                        </h6>
                                    </div>
                                    <div>
                                        <div>

                                            {!isCollapsed && <div className="mt-3">
                                                <p>The Hi5 Space community matrix's arrangement of space positions adheres to the principle of personal invitation-based community for space 1, whereas spaces 2 to 5 embrace the
                                                    principle of one world one team, one unified global community with power line movement, guided by an intelligent algorithm system known as the Space Loop set of protocols.</p>
                                                <p>The Hi5 Space matrix positions have the potential to greatly benefit our community by providing opportunities for financial freedom. By utilizing the repeated auto dynamic rebirth space
                                                    matrix positions with the same wallet address, it aims to increase the chances of receiving unlimited donations.</p>
                                                <p>This unique system offers lifetime permanent blockchain positions, as well as multiple automatic re-entry positions, multiple automatic upgrade positions, and pay-it-forward system. All
                                                    donations received through space matrix positions are directly deposited by smart contract into your private wallet.</p>
                                                <p>Furthermore, the space matrix incorporates an auto-pilot community builder powered by Hi5 Space Loop intelligent algorithm system, which enhances its effectiveness. It also includes
                                                    an automatic generative space position spillover feature, ensuring continuous growth within the space matrix positions.</p>
                                                <p>The space matrix positions are automatically filled in both forward and backward directions, mirroring each other to occupy balanced space matrix positions. This synchronized Space
                                                    Loop auto filling process within the space matrix allows for a harmonious distribution of positions, maintaining a consistent and symmetrical balanced space matrix structure for
                                                    everyone involved</p>
                                            </div>}
                                            <div className="">
                                                <Button className="bg-transparent text-primary border-0 mt-2 p-0" onClick={handleToggleCollapse}>
                                                    {isCollapsed ? 'Read More..' : 'Read Less..'}
                                                </Button>
                                            </div>
                                        </div>


                                    </div>

                                </Fade>
                            </Col>
                            <Col lg={6} md={6} className="mb-5">
                                <Zoom>
                                    <div className="infinity">
                                        <img src="assets/images/infinity.gif" />
                                        {/* <video width="100%" height="100%" controls>
                                            <source src="assets/images/infinty_1.mov" type="video/mov" />
                                                <source src="movie.ogg" type="video/ogg" />
                                                    Your browser does not support the video tag.
                                                </video> */}

                                    </div>
                                </Zoom>

                            </Col>
                        </Row>
                        <Row className="align-items-center">
                            <Col lg={6} md={6} className="mb-5">
                                <Zoom>
                                    <div className="water_circular text-center">
                                        <img src="assets/images/water_circule.gif" />
                                    </div>
                                </Zoom>
                            </Col>
                            <Col lg={6} md={6} className="mb-5">
                                <Fade right>
                                    <div className="heading">
                                        <h2 className="fw-bold lh-base mb-2 text-uppercase "><span className="text-primary">Hi5 space</span> is a platform that enables individuals to,</h2>
                                        <p className="mb-2">become successful social entrepreneurs, by providing them with excellent opportunities to generate financial resources. By bringing together committed social entrepreneurs from around the world. </p>
                                        <p className="mb-0">Hi5 Space  has the potential to foster collaboration, knowledge sharing,  and impactful solution to various social challenges, thus enabling us to change the world through unity in diversity and equality.</p>
                                    </div>
                                    {/* <Button className="bg-transparent text-primary border-0 mt-2 p-0">Read More..</Button> */}
                                </Fade>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </div>
            <div className="globe_earth">
                <section className="py-lg-5 pt-3 position-relative partner">
                    <Zoom>
                        <Container>
                            <Row className="justify-content-center">
                                <Col lg={3} md={3} sm={6} xs={6} className="mb-5">
                                    <div className="partner-logo">
                                        <a href="https://www.htx.com/" target="_blank">
                                            <img src="assets/images/partner/huobi.png" />
                                        </a>
                                    </div>
                                </Col>
                                <Col lg={3} md={3} sm={6} xs={6} className="mb-5">
                                    <div className="partner-logo">
                                        <a href="https://www.coinbase.com/" target="_blank">
                                            <img src="assets/images/partner/coinbase.png" />
                                        </a>
                                    </div>
                                </Col>
                                <Col lg={3} md={3} sm={6} xs={6} className="mb-5">
                                    <div className="partner-logo">
                                        <a href="https://uniswap.org/" target="_blank">
                                            <img src="assets/images/partner/uniswap.png" />
                                        </a>
                                    </div>
                                </Col>
                                <Col lg={3} md={3} sm={6} xs={6} className="mb-5">
                                    <div className="partner-logo">
                                        <a href="https://www.binance.com/en-IN" target="_blank">
                                            <img src="assets/images/partner/Binance.png" />
                                        </a>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Zoom>
                </section>
                <Footer />
            </div>
        </>
    );
};

export default Home;
