const config = {
    // baseUrl: '/',
    baseUrl: '/',
    // ApiURL:"http://localhost:8000/api/",
    //   ApiURLImage:"http://localhost:8000/api/image",
    //    linkUrl:"http://localhost:3000/Hi5_MLM/",
    // socketId:"http://localhost:8000/",

    // Dev //
//    linkUrl:"https://hi5space.espsofttech.org/",
//    ApiURL:"https://hi5space.espsofttech.org/api/",
//   ApiURLImage:"https://hi5space.espsofttech.org/api/image",
//      socketId:"https://hi5space.espsofttech.org/",



    // Production //
    linkUrl:"https://hi5space.com/",
    ApiURL:"https://hi5space.com/api/",
   ApiURLImage:"https://hi5space.com/media/",
     socketId:"https://hi5space.com:8888/",

 
};

export default config;