import React, { useState, useMemo, useEffect } from "react";
import config from "../../../../src/coreFiles/config";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Dropdown,
  ListGroup,
} from "react-bootstrap";
import { Zoom, Fade, Roll, Slide } from "react-reveal";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { Outlet, Link } from "react-router-dom";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { IoNotificationsOutline } from "react-icons/io5";
import { CiGlobe } from "react-icons/ci";
import { BsThreeDotsVertical } from "react-icons/bs";
import Dashboard from "../icon/dashboard";
import Network from "../icon/network";
import Donate from "../icon/donate";
import Spacetool from "../icon/spacetool";
import { AiOutlineDashboard } from "react-icons/ai";
import { IoSearch } from "react-icons/io5";
import { GoDotFill } from "react-icons/go";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import Spaceverification from "../icon/spaceverification";
import Referral from "../icon/referral";
import Spacewall from "../icon/spacewall";
import { FaProjectDiagram } from "react-icons/fa";
import Spacebothelp from "../icon/spacebothelp";
import Matrix from "../icon/matrix";
import Transaction from "../icon/transaction";
import Setting from "../icon/setting";
import "../css/sidebar.css";
import { isMobile } from "mobile-device-detect";
import Translator from "../../directives/Translator";
import { IoMdSettings } from "react-icons/io";
import { useDisconnect } from "wagmi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as ACTIONTYPES from "../../redux/actionTypes";
import { getUserDetailById } from "../../utils/apiServices.js";
import { useAccount } from "wagmi";
import toast, { Toaster } from "react-hot-toast";

const drawerWidth = 240;
const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: "#010000",
  color: "#fff",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflow: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    backgroundColor: "#010000",
    color: "#fff",
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "#010000",
  overflow: "hidden",

  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  backgroundColor: "#0c0c0c",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
  background: "#0c0c0c",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    backgroundColor: "#0c0c0c",
    color: "#fff",
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: "#0c0c0c",
    color: "#fff",
    transition: theme.transitions.create(["width", "margin"], {
      backgroundColor: "#0c0c0c",
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  backgroundColor: "#0c0c0c",
  fontFamily: "Saira",
  color: "#fff",
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const Sidebar = () => {
  let navigate = useNavigate();
  const loginDetails = useSelector((state) => state.auth.LoginDetails[0]);
  const isVerifieds = useSelector((state) => state.auth.isVerified);
  const AuthToken = useSelector((state) => state.auth.AuthToken);

  if (loginDetails == undefined) {
    let path = `${config.baseUrl}`;
    navigate(path);
  }
  const [formData, setFormData] = useState([
    loginDetails && {
      name: `${loginDetails.address
        .toString()
        .substring(0, 4)}...${loginDetails.address
        .toString()
        .substring(
          loginDetails.address.length - 4,
          loginDetails.address.length
        )}`,
      profileImage: "assets/images/dashboard/icon/usericon.png",
    },
  ]);

  const { isConnected, address } = useAccount();
  useEffect(() => {
    if (address == undefined) {
      dispatch({
        type: ACTIONTYPES.USERLOGOUT,
        payload: [],
        token: [],
      });
    }
  }, [isConnected]);

  const [showSideBar, setShowSideBar] = useState(isMobile ? false : true);
  const theme = useTheme();
  const [open, setOpen] = React.useState(isMobile ? false : true);
  const [openspacewall, spacewallsetOpen] = React.useState(false);
  const [openspacedonation, spacesetOpen] = React.useState(false);
  const [openspacenetwork, spacenetworkOpen] = React.useState(false);
  const [openspacebothelp, spacebothelpOpen] = React.useState(false);
  const [openspacetool, spacespacetoolOpen] = React.useState(false);
  const { disconnect } = useDisconnect();

  const dispatch = useDispatch();

  const spaceDonation = () => {
    if (openspacedonation == false) {
      setOpen(true);
      spacesetOpen(true);
    } else {
      spacesetOpen(false);
    }
  };
  const spaceWall = () => {
    if (openspacewall == false) {
      setOpen(true);
      spacewallsetOpen(true);
    } else {
      spacewallsetOpen(false);
    }
  };

  const spaceNetwork = () => {
    if (openspacenetwork == false) {
      setOpen(true);
      spacenetworkOpen(true);
    } else {
      spacenetworkOpen(false);
    }
  };
  const spaceBotHelp = () => {
    if (openspacebothelp == false) {
      setOpen(true);
      spacebothelpOpen(true);
    } else {
      spacebothelpOpen(false);
    }
  };
  const spaceTool = () => {
    if (openspacetool == false) {
      setOpen(true);
      spacespacetoolOpen(true);
    } else {
      spacespacetoolOpen(false);
    }
  };

  const handleDrawerOpen = () => {
    setShowSideBar(isMobile ? !showSideBar : true);
    if (open == true) {
      setOpen(false);
      spacenetworkOpen(false);
      spacesetOpen(false);
      spacespacetoolOpen(false);
      spacebothelpOpen(false);
      spacewallsetOpen(false);
    } else {
      setOpen(true);
    }
  };

  useEffect(() => {
    fetchUserDetail();
  }, []);
  const fetchUserDetail = async () => {
    let res = await getUserDetailById({ id: loginDetails.id }, AuthToken);

    if (res.data.success) {
      if (res.data.data.length > 0) {
        if (res.data.data[0].name != null) {
          let data = res.data.data;
          data[0].profileImage = `${config.ApiURL}profilePic/${data[0].profileImage}`;
          setFormData(data);
        }
      }
    }
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const pageRedirect=(e,pageR)=>{
    e.preventDefault();
    let path = pageR=="setting"?`${config.baseUrl}setting`:`${config.baseUrl}spaceverification`;
    navigate(path);
  }

  const logout = async (e) => {
    e.preventDefault();

    try {
      disconnect();
      setTimeout(() => {
        callDispatch();
      }, 2000);
    } catch (error) {
      console.log(
        error.data
          ? error.data.message
            ? error.data.message
            : error.data
          : error.message
          ? error.message
          : error
      );
    }
  };
  const callDispatch = () => {
    dispatch({
      type: ACTIONTYPES.USERLOGOUT,
      payload: [],
      token: [],
    });
  };

  const handleSideBar = () => {
    if (open == true) {
      setOpen(false);
    }
  };

  const errorForVerification = (e) => {
    e.preventDefault();
    toast.error("Please Verify your details");
  };
  return (
    <>
      <Toaster />
      <CssBaseline />
      <AppBar
        position="fixed"
        style={{ left: !isMobile && !open ? "auto" : "auto" }}
        open={isMobile ? false : open}
      >
        <div className="logoMobile text-center mb-2 py-2 border-bottom border-dark">
          <Link to={`${config.baseUrl}`}>
            <img src="assets/images/logo.png" width="180px" />
          </Link>
        </div>
        <Toolbar className="w-100">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
          >
            <MenuIcon />
          </IconButton>

          <DrawerHeader className="headerSearch w-100">
            <Container fluid>
              <Row className="align-items-center">
                <Col lg={4} md={4} sm={4}>
                  <Form className="">
                    <div className="searchfield position-relative">
                      <span className="search-icon">
                        <IoSearch />
                      </span>
                      <Form.Control
                        type="search"
                        placeholder="Search"
                        className="me-2"
                        aria-label="Search"
                      />
                    </div>
                  </Form>
                </Col>
                <Col lg={8} md={8} sm={8} className="text-right">
                  <div className="header-nav">
                    <ul className="mb-0">
                      <li className="nav-item">
                        <a>
                          <span href="#" className="nav-link translator">
                            <Translator className="" />
                          </span>
                        </a>
                      </li>
                      <li className="nav-item">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="default"
                            id="dropdown-basic"
                            className="border-0 bg-transparent"
                          >
                            <span className="profile-icon me-2">
                              {console.log(formData[0])}
                              {formData.length > 0 && (
                                <img src={`${formData[0].profileImage}`} />
                              )}
                            </span>
                            {formData.length > 0 && formData[0].name}
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="bg-white">
                            <Dropdown.Item href="#/action-2 text-black"
                            onClick={(e) => {
                              pageRedirect(e,"setting");
                            }}>
                              Setting
                            </Dropdown.Item>
                            <Dropdown.Item href="#/action-2 text-black"
                             onClick={(e) => {
                              pageRedirect(e,"spaceverification");
                            }}>
                              Profile
                            </Dropdown.Item>
                            <Dropdown.Item
                              href="#/action-3 text-black"
                              onClick={(e) => {
                                logout(e);
                              }}
                            >
                              Logout
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </DrawerHeader>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        anchor="left"
        className={`sidebar ${!showSideBar ? "d-none" : ""}`}
        open={open}
        style={{ backgroundColor: "#000" }}
      >
        <DrawerHeader>
          <div className="logo">
            {open ? (
              <Link to={`${config.baseUrl}`}>
                <img src="assets/images/logo.png" width="180px" />
              </Link>
            ) : (
              <img src="assets/images/favicon.png" width="180px" />
            )}
          </div>
        </DrawerHeader>
        <Divider />
        <div className="navbarlist">
          <List>
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              component={Link}
              to={`${config.baseUrl}dashboard`}
              onClick={handleSideBar}
            >
              <ListItemButton
                sx={{
                  minHeight: 40,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  py: "4px",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Dashboard />
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  Dashboard
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              component={Link}
              to={`${config.baseUrl}spaceInvite`}
            >
              <ListItemButton
                sx={{
                  minHeight: 40,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  py: "0px",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Referral fill="#fff" />
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  Space Welcome
                </ListItemText>
              </ListItemButton>
            </ListItem>

            <ListItem
              disablePadding
              sx={{ display: "block" }}
              style={{ left: !isMobile && !open ? "poition-fixed" : "" }}
              open={isMobile ? false : open}
            >
              <ListItemButton
                onClick={spaceNetwork}
                sx={{
                  minHeight: 40,
                  padding: "0px 20px",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: "auto",
                    marginRight: "24px",
                  }}
                >
                  <Network fill="#fff" />
                </ListItemIcon>

                <ListItemText primary="Space Network" />
                {openspacenetwork ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openspacenetwork} timeout="auto">
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to={`${config.baseUrl}spacenetwork`}
                  >
                    {/* <ListItemIcon>
                                    <GoDotFill />
                                </ListItemIcon> */}
                    <ListItemText primary="Direct Referrals " />
                  </ListItemButton>
                </List>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to={`${config.baseUrl}MatrixMembers`}
                  >
                    <ListItemText primary="Matrix Members" />
                  </ListItemButton>
                </List>
              </Collapse>
            </ListItem>
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              component={Link}
              to={`${config.baseUrl}invitedonation`}
            >
              <ListItemButton
                sx={{
                  minHeight: 40,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  py: "0px",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <InboxIcon stroke="#E1B028" />
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  Space Donation
                </ListItemText>
              </ListItemButton>
            </ListItem>
            {/* <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={spaceDonation}
                sx={{
                  padding: "8px 20px",
                }}
              >
                <ListItemIcon
                  fill="#E1B028"
                  sx={{
                    minWidth: "auto",
                    marginRight: "24px",
                  }}
                >
                  <InboxIcon stroke="#E1B028" />
                </ListItemIcon>
                
                <ListItemText primary="Space Donation" />
                {openspacedonation ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openspacedonation} timeout="auto" className="">
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to={`${config.baseUrl}invitedonation`}
                  >
                    <ListItemText primary="Invite Donation" />
                  </ListItemButton>
                </List>
                
              </Collapse>
            </ListItem> */}

            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={spaceWall}
                sx={{
                  minHeight: 40,
                  padding: "0px 20px",
                }}
              >
                <ListItemIcon
                  fill="#E1B028"
                  sx={{
                    minWidth: "auto",
                    marginRight: "24px",
                  }}
                >
                  <Spacewall fill="#fff" />
                </ListItemIcon>
                <ListItemText primary="Space Wall" />
                {openspacewall ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openspacewall} timeout="auto">
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to={`${config.baseUrl}upload`}
                  >
                    {/* <ListItemIcon>
                                    <GoDotFill />
                                </ListItemIcon> */}
                    <ListItemText primary="Uploads" />
                  </ListItemButton>
                </List>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to={`${config.baseUrl}letsdonate`}
                  >
                    {/* <ListItemIcon>
                                    <GoDotFill /> <GoDotFill />
                                </ListItemIcon> */}
                    <ListItemText primary="Let's Donate " />
                  </ListItemButton>
                </List>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to={`${config.baseUrl}activities`}
                  >
                    {/* <ListItemIcon>
                                    <GoDotFill /> <GoDotFill />
                                </ListItemIcon> */}
                    <ListItemText primary="Activities " />
                  </ListItemButton>
                </List>
              </Collapse>
            </ListItem>

            {/* <ListItem disablePadding sx={{ display: 'block' }} component={Link} to={`${config.baseUrl}Spacewallupload`}>
                          
                            <ListItemButton
                                sx={{
                                    minHeight: 40,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Spacewall fill="#fff" />
                                </ListItemIcon>
                                <ListItemText sx={{ opacity: open ? 1 : 0 }} >Space Wall</ListItemText>
                            </ListItemButton>
                        </ListItem> */}

            <ListItem
              disablePadding
              sx={{ display: "block" }}
              component={Link}
              to={`${config.baseUrl}spaceverification`}
            >
              <ListItemButton
                sx={{
                  minHeight: 40,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  py: "0px",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Spaceverification fill="#fff" />
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  Space Verification
                </ListItemText>
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={spaceTool}
                sx={{
                  minHeight: 40,
                  padding: "0px 20px",
                }}
              >
                <ListItemIcon
                  fill="#E1B028"
                  sx={{
                    minWidth: "auto",
                    marginRight: "24px",
                  }}
                >
                  <Spacetool fill="#fff" />
                </ListItemIcon>
                <ListItemText primary="Space Tools" />
                {openspacetool ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openspacetool} timeout="auto">
                {isVerifieds ? (
                  <>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        component={Link}
                        to={`${config.baseUrl}Hi5PowerPoint`}
                      >
                        <ListItemText primary="PPT" />
                      </ListItemButton>
                    </List>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        component={Link}
                        to={`${config.baseUrl}Emailscript`}
                      >
                        <ListItemText primary="Script " />
                      </ListItemButton>
                    </List>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        component={Link}
                        to={`${config.baseUrl}leaflet`}
                      >
                        <ListItemText primary="Leaflet " />
                      </ListItemButton>
                    </List>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        component={Link}
                        to={`${config.baseUrl}miniVideos`}
                      >
                        <ListItemText primary="Mini Videos " />
                      </ListItemButton>
                    </List>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        component={Link}
                        to={`${config.baseUrl}Sociallink`}
                      >
                        <ListItemText primary="Social Links " />
                      </ListItemButton>
                    </List>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        component={Link}
                        to={`${config.baseUrl}LoyaltyItems`}
                      >
                        <ListItemText primary="Marketing Gift " />
                      </ListItemButton>
                    </List>
                  </>
                ) : (
                  <>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={(e) => {
                          errorForVerification(e);
                        }}
                      >
                        <ListItemText primary="PPT" />
                      </ListItemButton>
                    </List>
                    {/* <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={e => { errorForVerification(e) }}

                      >
                        <ListItemText primary="Script " />
                      </ListItemButton>
                    </List> */}
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={(e) => {
                          errorForVerification(e);
                        }}
                      >
                        <ListItemText primary="Leaflet " />
                      </ListItemButton>
                    </List>
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={(e) => {
                          errorForVerification(e);
                        }}
                      >
                        <ListItemText primary="Mini Videos " />
                      </ListItemButton>
                    </List>
                    {/* <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={e => { errorForVerification(e) }}

                      >
                        <ListItemText primary="Social Links " />
                      </ListItemButton>
                    </List> */}
                    <List component="div" disablePadding>
                      <ListItemButton
                        sx={{ pl: 4 }}
                        onClick={(e) => {
                          errorForVerification(e);
                        }}
                      >
                        <ListItemText primary="Marketing Gift " />
                      </ListItemButton>
                    </List>
                  </>
                )}
              </Collapse>
            </ListItem>

            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                onClick={spaceBotHelp}
                sx={{
                  minHeight: 40,
                  padding: "0px 20px",
                }}
              >
                <ListItemIcon
                  fill="#E1B028"
                  sx={{
                    minWidth: "auto",
                    marginRight: "24px",
                  }}
                >
                  <Spacebothelp fill="#fff" />
                </ListItemIcon>
                <ListItemText primary="Space Help" />
                {openspacebothelp ? <ExpandLess /> : <ExpandMore />}
              </ListItemButton>
              <Collapse in={openspacebothelp} timeout="auto">
              {isVerifieds ?(<>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to={`${config.baseUrl}bothelp`}
                  >
                    {/* <ListItemIcon>
                                   <GoDotFill />
                               </ListItemIcon> */}
                    <ListItemText primary="Bot Help" />
                  </ListItemButton>
                </List>
                <List component="div" disablePadding>
                  <ListItemButton
                    sx={{ pl: 4 }}
                    component={Link}
                    to={`${config.baseUrl}teammateshelp`}
                  >
                    {/* <ListItemIcon>
                                   <GoDotFill /> <GoDotFill />
                               </ListItemIcon> */}
                    <ListItemText primary="Teammates Help" />
                  </ListItemButton>
                </List>
                </>):
                (<>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                     
                      onClick={(e) => {
                        errorForVerification(e);
                      }}
                    >

                      <ListItemText primary="Bot Help" />
                    </ListItemButton>
                  </List>
                  <List component="div" disablePadding>
                    <ListItemButton
                      sx={{ pl: 4 }}
                     
                      onClick={(e) => {
                        errorForVerification(e);
                      }}
                    >
                      
                      <ListItemText primary="Teammates Help" />
                    </ListItemButton>
                  </List>
                  </>)
                }
              </Collapse>
            </ListItem>

            {/* <ListItem disablePadding sx={{ display: 'block' }} component={Link} to={`${config.baseUrl}spacebothelp`}>
                            <ListItemButton
                                sx={{
                                    minHeight: 40,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Spacebothelp fill="#fff" />
                                </ListItemIcon>
                                <ListItemText sx={{ opacity: open ? 1 : 0 }} >Space Bot Help</ListItemText>
                            </ListItemButton>
                        </ListItem> */}

            <ListItem
              disablePadding
              sx={{ display: "block" }}
              component={Link}
              to={`${config.baseUrl}referral`}
            >
              <ListItemButton
                sx={{
                  minHeight: 40,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  py: "0px",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Referral fill="#fff" />
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  Space Invite Link
                </ListItemText>
              </ListItemButton>
            </ListItem>
            {/* <ListItem
                disablePadding
                sx={{ display: "block" }}
                component={Link}
                to={`${config.baseUrl}referraltree`}
              >
                <ListItemButton
                  sx={{
                    minHeight: 40,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <Matrix fill="#fff" />
                  </ListItemIcon>
                  <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                    Matrix
                  </ListItemText>
                </ListItemButton>
              </ListItem> */}
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              component={Link}
              to={`${config.baseUrl}transaction`}
            >
              <ListItemButton
                sx={{
                  minHeight: 40,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  py: "0px",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Transaction fill="#fff" />
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  Space Log
                </ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              component={Link}
              to={`${config.baseUrl}setting`}
            >
              <ListItemButton
                sx={{
                  minHeight: 40,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  py: "0px",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <Setting fill="#fff" />
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>T&C</ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem
              disablePadding
              sx={{ display: "block" }}
              component={Link}
              to={`${config.baseUrl}spaceconnect`}
            >
              <ListItemButton
                sx={{
                  minHeight: 40,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                  py: "0px",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <FaProjectDiagram fill="#fff" />
                </ListItemIcon>
                <ListItemText sx={{ opacity: open ? 1 : 0 }}>
                  Space Connect
                </ListItemText>
              </ListItemButton>
            </ListItem>
            {/* <ListItemButton onClick={handleClick}>
                        <ListItemIcon>
                            <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary="Inbox" />
                        {open1 ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={open1} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton sx={{ pl: 4 }}>
                                <ListItemIcon>
                                    <StarBorder />
                                </ListItemIcon>
                                <ListItemText primary="Starred" />
                            </ListItemButton>
                        </List>
                    </Collapse> */}
            {/* {['Dashboard', 'Space Network', 'Space Donation', 'Space Wall', 'Space Verification', 'Space Tools', 'Space Bot Help', 'Referrals', 'Matrix', 'Transactions', 'Setting'].map((text, index) => (
                        <ListItem key={text} disablePadding sx={{ display: 'block' }}>
                            <ListItemButton
                                sx={{
                                    minHeight: 40,
                                    justifyContent: open ? 'initial' : 'center',
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : 'auto',
                                        justifyContent: 'center',
                                    }}
                                >
                                    {index % 5 === 0 ? <InboxIcon fill="#fff" /> : <MailIcon fill="#fff" />}
                                </ListItemIcon>
                                <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                            </ListItemButton>
                        </ListItem>
                    ))} */}
          </List>
        </div>
        <Divider />
        <List>
          {/* <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                    sx={{
                        minHeight: 40,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 3 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        <InboxIcon /> 
                    </ListItemIcon>
                    <ListItemText sx={{ opacity: open ? 1 : 0 }} >
                        dfgdfgdfgfd
                        </ListItemText>
                </ListItemButton>
            </ListItem> */}
        </List>
      </Drawer>
    </>
  );
};

export default Sidebar;
